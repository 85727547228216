<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-card title="Objectifs de vente globaux">
          <donut-chart
            :data="data"
            :title="title"
            :value-field="'value'"
            :category-field="'category'"
          />
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Objectifs de vente individuels">
          <stacked-column-chart
            :data="data1"
            category-field="commercial"
            :series="series1"
            x-axis-title="Commerciaux"
            y-axis-title="Objectifs de vente"
          />

        </b-card>
      </b-col>
      <b-col md="12">
        <b-card title="Performances des commerciaux par zone géographique et par produit ">
          <force-directed-tree
            :data="performanceData"
            node-title="name"
            link-title="count"
            link-property="count"
            :node-width="Number('50')"
            :node-height="Number('50')"
          />

        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Taux de conversion des prospects">
          <donut-chart
            :data="conversionData"
            value-field="value"
            category-field="category"
          />
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Taux de perte de clients">
          <DonutChart
            :data="data2"
            title="Taux de perte de clients"
            value-field="value"
            category-field="category"
          />
        </b-card>
      </b-col>

    </b-row>
  </div>

</template>

<script>
import DonutChart from '@/views/m-components/charts/DonutChart.vue'
import { BCard, BCol, BRow } from 'bootstrap-vue'
import StackedColumnChart from '@/views/m-components/charts/StackedColumnChart.vue'
import ForceDirectedTree from '@/views/m-components/charts/ForceDirectedTree.vue'

export default {
  name: 'MainVue',
  components: {
    ForceDirectedTree,
    StackedColumnChart,
    DonutChart,
    BCard,
    BRow,
    BCol,
  },
  data() {
    return {
      title: 'Objectifs de vente globaux',
      data: [
        {
          category: 'Objectif de vente atteint',
          value: 80,
        },
        {
          category: 'Objectif de vente à atteindre',
          value: 20,
        },
      ],
      data1: [
        { commercial: 'Mariam', objectif1: 50, objectif2: 70 },
        { commercial: 'Adama', objectif1: 70, objectif2: 80 },
        { commercial: 'FARIDA', objectif1: 60, objectif2: 90 },
        { commercial: 'Maïmouna', objectif1: 80, objectif2: 70 },
        { commercial: 'AZIZ', objectif1: 40, objectif2: 60 },
      ],
      series1: [
        { seriesName: 'Objectif 1', valueField: 'objectif1', color: '#FFB900' },
        { seriesName: 'Objectif 2', valueField: 'objectif2', color: '#008000' },
      ],
      performanceData: [
        {
          name: 'Commercial A',
          children: [
            {
              name: 'Zone 1',
              children: [
                { name: 'Produit 1', value: 300 },
                { name: 'Produit 2', value: 250 },
                { name: 'Produit 3', value: 200 },
              ],
            },
            {
              name: 'Zone 2',
              children: [
                { name: 'Produit 1', value: 350 },
                { name: 'Produit 2', value: 150 },
                { name: 'Produit 3', value: 100 },
              ],
            },
          ],
        },
        {
          name: 'Commercial B',
          children: [
            {
              name: 'Zone 1',
              children: [
                { name: 'Produit 1', value: 250 },
                { name: 'Produit 2', value: 200 },
                { name: 'Produit 3', value: 150 },
              ],
            },
            {
              name: 'Zone 2',
              children: [
                { name: 'Produit 1', value: 300 },
                { name: 'Produit 2', value: 100 },
                { name: 'Produit 3', value: 50 },
              ],
            },
          ],
        },
        {
          name: 'Commercial C',
          children: [
            {
              name: 'Zone 1',
              children: [
                { name: 'Produit 1', value: 250 },
                { name: 'Produit 2', value: 200 },
                { name: 'Produit 3', value: 1050 },
              ],
            },
            {
              name: 'Zone 2',
              children: [
                { name: 'Produit 1', value: 300 },
                { name: 'Produit 2', value: 100 },
                { name: 'Produit 3', value: 500 },
              ],
            },
          ],
        },
        {
          name: 'Commercial D',
          children: [
            {
              name: 'Zone 1',
              children: [
                { name: 'Produit 1', value: 250 },
                { name: 'Produit 2', value: 200 },
                { name: 'Produit 3', value: 2050 },
              ],
            },
            {
              name: 'Zone 2',
              children: [
                { name: 'Produit 1', value: 300 },
                { name: 'Produit 2', value: 400 },
                { name: 'Produit 3', value: 500 },
              ],
            },
          ],
        },
      ],
      conversionData: [
        { category: 'Prospects contactés', value: 70 },
        { category: 'Prospects convertis en clients', value: 30 },
      ],
      data2: [
        { category: 'Abandon du produit', value: 25 },
        { category: 'Insatisfaction du service', value: 35 },
        { category: 'Prix trop élevé', value: 15 },
        { category: 'Concurrence', value: 20 },
        { category: 'Autre', value: 5 },
      ],
    }
  },
}
</script>

<style scoped>

</style>
